<div class="metadata-div">

  <span class="input-field-label" [matTooltip]="label" matTooltipPosition="above">
    <b>{{label}}</b>
  </span>

  <div *ngIf="visibility === 'MT_O'" class="only-read-metadata-data-div">
    {{humanvalue}}
  </div>

  <mat-form-field *ngIf="visibility === 'MT_K' || visibility === 'MT_M'">
    <textarea matInput #input
              [formControl]="textareaFormControl"
              [value]="value"
              maxlength="1000"
              cdkTextareaAutosize
              cdkAutosizeMaxRows="16"
              (input)="onChangeValue(input.value)"></textarea>
    <mat-hint align="end">{{input.value.length}}/1000</mat-hint>
    <mat-error *ngIf="textareaFormControl.hasError('required')">
      Kötelező mező!
    </mat-error>
  </mat-form-field>

</div>
