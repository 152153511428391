import {Component} from '@angular/core';
import {ErrorPageService} from "../../services/shared/error-page.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(
    public errorPageService: ErrorPageService,
    private router: Router) {
  }

  get errorMessage(){
    return this.errorPageService.errorMessage;
  }

  backToPage() {
    /*if (this.error.errorMessage.includes('workflow feladat már lezárásra került!')) {
      this.router.navigate(['/workflow/list']);
    } else if (this.error.errorMessage.includes('task feladat már lezárásra került!') ||
                this.error.errorMessage.includes('task feladat még nem került elfogadásra!'))
    {
      this.router.navigate(['/task/list']);
    } else {
      history.back();
    }*/
    this.router.navigate(['/login']);
  }

}
