<div class="metadata-div">
  <span class="input-field-label" [matTooltip]="label" matTooltipPosition="above">
    <b>{{label}}</b>
  </span>
  <div class="only-read-metadata-data-div-check" [ngClass]="getBorderClass()">
    <mat-checkbox labelPosition="after"
                  color="primary"
                  [(ngModel)]="value"
                  (change)="onChangeValue(value)" [disabled]="visibility==='MT_O'">
      <span [ngClass]="{'disabled-text': visibility==='MT_O'}">
        {{value ? 'Igen' : 'Nem'}}
      </span>
    </mat-checkbox>
  </div>
  <mat-error *ngIf="visibility==='MT_K'&&!this.value" class="warning-text">Kötelező mező!</mat-error>
</div>
<!--<div class="metadata-div">-->
<!--  <div class="only-read-metadata-data-div">-->
<!--    <mat-checkbox labelPosition="after" [(ngModel)]="value" (change)="onChangeValue(value)">-->
<!--    <b>{{label}}</b>-->
<!--    </mat-checkbox>-->
<!--  </div>-->
<!--</div>-->
