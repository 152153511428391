import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SidenavToggleService} from "../../../services/shared/sidenav-toggle.service";
import {ActivationEnd, NavigationEnd, Router} from "@angular/router";
import {UserEmailService} from "../../../services/component/sidenav/user-email.service";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit{

  noHeader: boolean = false;

  selectedTabIndex: number = 0;

  isSmallScreen: boolean = false;

  isAdminPage = false;

  constructor(
    public sidenavToggle: SidenavToggleService,
    private router: Router,
    public userEmailService: UserEmailService,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {
    if(window.location.href.includes('/document/')){
      this.selectedTabIndex = 1;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAdminPage = window.location.href.includes('/admin');
        this.updateSelectedTab(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if(result.breakpoints["(max-width: 768px)"]) {
        this.isSmallScreen = true;
        this.cdr.detectChanges();
      } else {
        this.isSmallScreen = false;
        this.cdr.detectChanges();
      }
    });
    this.router.events.subscribe(event => {
      if(event instanceof ActivationEnd){
        if(window.location.href.includes('login')){
          this.noHeader = true;
        } else {
          this.noHeader = false;
        }
      }
    })
  }

  updateSelectedTab(url: string): void {
    if (url.startsWith('/dashboard') || url.startsWith('/workflow') || url.startsWith('/task')) {
      this.selectedTabIndex = 0;
    } else if (url.startsWith('/document')) {
      this.selectedTabIndex = 1;
    }
  }

  onTabChange(index: number): void {
    if (index === 0) {
      this.router.navigate(['/dashboard']);
    } else if (index === 1) {
      this.router.navigate(['/document/list']);
    }
  }

  logOut(){
    this.userEmailService.emailAddress=''; //todo: ez csak ideiglenes, ki kell jelentkeztetni a felhasználót
    this.userEmailService.setLoggedInEmail$('');
    this.router.navigate(['/login']);
  }

  openSidenav(){
    if(this.isSmallScreen || this.isAdminPage) {
      this.sidenavToggle.isShowing = true;
    }
  }

}
