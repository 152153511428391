import {Component, OnInit} from '@angular/core';
import { navbarDataUser, navbarDataAdmin} from "./nav-data";
import {SidenavToggleService} from "../../../services/shared/sidenav-toggle.service";
import {ActivationStart, Router} from "@angular/router";
import {UserEmailService} from "../../../services/component/sidenav/user-email.service";
import {filter} from "rxjs";
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit{

  isAdminPage: boolean = false;

  constructor(
    public sidenavToggle: SidenavToggleService,
    public router: Router,
    public userEmailService: UserEmailService
  ) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => Reflect.has(event, 'snapshot') && event instanceof ActivationStart)
    ).subscribe((event: any)=>{
      this.isAdminPage = Boolean(event.snapshot.data.admin);
    })
  }

  logOut(){
    this.userEmailService.emailAddress=''; //todo: ez csak ideiglenes, ki kell jelentkeztetni a felhasználót
    this.userEmailService.setLoggedInEmail$('');
    this.router.navigate(['/login']);
  }

  isAdmin(){
    if(this.isAdminPage){
      return navbarDataAdmin;
    } else {
      return navbarDataUser;
    }
  }

}
