import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DmsMetaService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http) }

  readDmsPartnerById$(id: number){
      return this.read$<any>(`partner/${id}`)
  }

  readDmsAcPartners$(user: string){
    if (user.length == 0) user = '%';
    let params: HttpParams = new HttpParams();
    params = params.append("name", user)
      return this.read$<any>(`ac/partner`, params)
  }

  readDmsMfTorzsListByLeiroId$(leiroIdList: string){
      return this.read$<any>(`mfTorzs/${leiroIdList}`)
  }

}
