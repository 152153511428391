import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";

@Injectable({
  providedIn: 'root'
})
export class DmsAttachmentsService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http); }

  readDmsAttachmentByGuid$(attachmentGuid: string){
    window.open(`/api/dms/attachment/${attachmentGuid}/download`);
    //window.location.href = `/api/dms/attachment/${attachmentGuid}/download`;
    /*return this.read$<any>(`/attachment/${attachmentGuid}/download`)*/

  }

}
