
<div class="sidenav">

    <div class="logo-container">
      <button mat-mini-fab disabled
              style="box-shadow: none; pointer-events: none; color: white; background-color: #b0b0b0; margin:0">
        <mat-icon>person</mat-icon>
      </button>
      <div class="logo-text">{{userEmailService.emailAddress}}</div>
      <span class="example-spacer"></span>
      <mat-icon class="close-icon" (click)="sidenavToggle.isShowing=false">arrow_back_ios</mat-icon>
    </div>

  <ul class="sidenav-nav">

    <li class="sidenav-nav-item" *ngFor="let data of isAdmin()">
      <a class="sidenav-nav-link" [routerLink]="[data.routeLink]"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}"
         (click)="sidenavToggle.isShowing=false">
        <mat-icon class="sidenav-link-icon">{{data.icon}}</mat-icon>
        <span class="sidenav-link-text">{{data.label}}</span>
      </a>
    </li>

    <li class="sidenav-nav-item-bottom">
      <a class="sidenav-nav-link-bottom" (click)="logOut();sidenavToggle.isShowing=false">
        <mat-icon class="sidenav-link-icon-bottom">exit_to_app</mat-icon>
        <span class="sidenav-link-text-bottom">Kijelentkezés</span>
      </a>
    </li>

  </ul>
</div>
