import {Component, Input, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-meta-data-option',
  templateUrl: './meta-data-option.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataOptionComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  @Input() default: string = '';

  options: {valueView: string, valueIndex: string}[]=[];

  optionFormControl!: FormControl;

  ngOnInit() {
    if(this.default&&this.default.length>0) {
      const tempList = this.default.split('|');
      const optionList = tempList[1].split(';');
      this.options = optionList.map((valueView, valueIndex) => {
        const tempNum = valueIndex+1;
        return {valueView, valueIndex: tempNum.toString()}
      })
      if(this.visibility != 'MT_K') {
        this.options.unshift({valueView: 'Nincs kiválasztva', valueIndex: ''});
      }
    }
    this.initializeFormControl();
    this.optionFormControl.markAllAsTouched();
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.optionFormControl = new FormControl(this.value, validators);
  }
}
