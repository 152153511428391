<h2 mat-dialog-title>Feladat továbbítása</h2>
<mat-dialog-content>
  <p>{{data.name}} továbbítása az alábbi személynek:</p>
  <p>
    <b>{{sendUser.userName}} {{sendUser.orgName ? '('+sendUser.orgName+')' : ''}}</b>
  </p>
  <p class="input-field-label">
    <b>Átvevő felhasználó</b>
  </p>
  <mat-form-field style="width: 100%">
    <input #input
           type="text"
           placeholder="Felhasználó keresése..."
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)="sendUser = $event.option.value">
      <mat-option *ngIf="notFound" disabled>Nincs találat</mat-option>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.userName}} ({{option.orgName}})
      </mat-option>
    </mat-autocomplete>
    <button matSuffix mat-icon-button (click)="onSearchClicked(input.value)" class="search-button">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="onNoClick()" class="close-button">Mégsem</button>
  <button mat-raised-button color="primary" class="forward-button"
          [disabled]="!(sendUser.userId != 0 && sendUser.orgId != 0)"
          (click)="onSendClick()">
    Továbbítás
    <mat-icon>arrow_right_alt</mat-icon>
  </button>
</mat-dialog-actions>

