export const navbarDataUser = [
  {
    routeLink: 'dashboard',
    icon: 'home',
    label: 'Feladataim'
  },
  {
    routeLink: 'document/list',
    icon: 'folder_open',
    label: 'Dokumentumaim'
  },
];

export const navbarDataAdmin = [
  {
    routeLink: 'admin/station-list',
    icon: 'dns',
    label: 'Station management'
  },
  {
    routeLink: 'admin/station-new',
    icon: 'add_box',
    label: 'Station create'
  },
  {
    routeLink: 'admin/user-list',
    icon: 'face',
    label: 'User management'
  },
  {
    routeLink: 'admin/user-activation-control',
    icon: 'how_to_reg',
    label: 'User-Station management'
  },
];
