import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {map, Observable, of, switchMap} from 'rxjs';
import {DmsNewsService} from "../services/api/dms-news.service";
import {DmsMetaService} from "../services/api/dms-meta.service";
import {DmsNewsListResponseSchema} from "../model/dmsNewsListResponseSchema";
import {DmsMfTorzsItemsByLeiro} from "../model/dmsMfTorzsItemsByLeiro";
import {tap} from "rxjs/operators";
import {FilterDataService} from "../services/component/news-page/filter-data.service";

@Injectable({
  providedIn: 'root'
})
export class NewsResolver  {

  private readonly newsService: DmsNewsService = inject(DmsNewsService);
  private readonly dmsMetaService: DmsMetaService = inject(DmsMetaService);
  private readonly filterDataService: FilterDataService = inject(FilterDataService);

  //private wfIncomingDataNews: DmsNewsListResponseSchema = [];
  //private taskIncomingDataNews: DmsNewsListResponseSchema = [];

  private allIncomingDataNews: DmsNewsListResponseSchema = [];

  //private taskNumberMessage = '';
  //private wfNumberMessage = '';

  private descriptorIdSet = new Set<string>();

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.newsService.readDmsNews$(this.filterDataService.showAllTasks).pipe(
      tap(data => {
      this.processNewsData(data.result);
      }),
      switchMap((data)=> {
        let obs$: Observable<DmsNewsListResponseSchema | DmsMfTorzsItemsByLeiro> = of(data);
        if(this.descriptorIdSet.size>0){
          obs$ = this.dmsMetaService.readDmsMfTorzsListByLeiroId$(Array.from(this.descriptorIdSet).join(',')).pipe(
            map((result) => result.result)
          );
        }
        return obs$;
      }),
      map((data) => {
        //console.log(this.wfNumberMessage)
        return {
          //wfIncomingDataNews: this.wfIncomingDataNews,
          //taskIncomingDataNews: this.taskIncomingDataNews,
          allIncomingDataNews: this.allIncomingDataNews,
          mfMasterOptionList: data,
          //taskNumberMessage: this.taskNumberMessage,
          //wfNumberMessage: this.wfNumberMessage
        }
      })
    );
  }

  private processNewsData(incomingDataNews: DmsNewsListResponseSchema) {

    const valueChangeTypes = ['Alszam', 'Foszam'];
    const masterTypes = ['MFTorzs', 'MFTorzsAC'];

    this.descriptorIdSet.clear();
    this.allIncomingDataNews = [];

    incomingDataNews.forEach(item => {

      if(item.type === 'WfTask') {
        const isActiveTask = this.isActiveStatus(item.status);
        item.metaItems?.forEach(metaData => {
          if (valueChangeTypes.includes(metaData.type)) {
            metaData.value = metaData.humanvalue;
          }
          if (masterTypes.includes(metaData.type) && metaData.visibility !== 'MT_O') {
            this.descriptorIdSet.add(metaData.params.toString());
          }
          if(!isActiveTask && metaData.visibility != 'MT_N'){
            metaData.visibility = 'MT_O';
          }
        });
      }

      if(
        (item.type === 'WfTask' && this.filterDataService.showWorkflow) ||
        (item.type === 'Task' && this.filterDataService.showTask)
      ){
        this.allIncomingDataNews.push(item);
      }

    });

  }

  private isActiveStatus(status: string | undefined){
    return status === '1_elfogadva' || status === '1_letrehozva' || status === 'FA_U';
  }

}
