<mat-toolbar style="padding-left: 0;  top: 0; z-index: 10; background: white; box-shadow: 0 0 4px rgba(0, 0, 0, 0.2)"
             *ngIf="!noHeader">
  <button mat-icon-button *ngIf="isSmallScreen || isAdminPage" (click)="openSidenav()"><mat-icon>menu</mat-icon></button>
  <div style="background: white; display: flex;
            justify-content: center;
            align-items: center;
            height: 100%; font-size: x-large; padding-left: 16px">
    <img src="assets/dmsone-logo.png">
  </div>
  <!--<button mat-icon-button *ngIf="showMenu" (click)="navigationBack()">
    <mat-icon>arrow_back_icon</mat-icon>
  </button>-->

  <mat-tab-group *ngIf="!isSmallScreen && !isAdminPage"
                 (selectedIndexChange)="onTabChange($event)"
                 [selectedIndex]="selectedTabIndex">
    <mat-tab label="Feladatok"></mat-tab>
    <mat-tab label="Dokumentumok"></mat-tab>
  </mat-tab-group>

  <span *ngIf="!isSmallScreen" class="example-spacer"></span>
  <span *ngIf="!isSmallScreen" style="font-size: small; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{userEmailService.emailAddress}}</span>
  <div class="badge-circle" *ngIf="!isSmallScreen">{{userEmailService.emailAddress?.substring(0,1)?.toUpperCase()}}</div>
  <button mat-flat-button
          *ngIf="!isSmallScreen"
          (click)="logOut()"
          style="font-size: 12px; padding-right: 0; min-width: 122px">
    Kijelentkezés
    <mat-icon>exit_to_app</mat-icon>
  </button>

</mat-toolbar>
