/**
 * User: harold
 * Date: 2023.08.15.
 */


// const tenantId = '7b8d1e4e-b3a3-4df5-a12c-056ac9cdcbf4';
// const clientId = '40b041a7-1128-4829-b0c2-abf5c058b569';
// const redirectUri = 'http://localhost:1234/';
// const apiId = `062da26d-b94e-424d-b119-b6b12fc46618`
// const apiUri = `api://${apiId}`;
// const scopes = [`${apiUri}/all:full`];


const config = {
  google: {
    clientId: '958445996833-kj2g88md641lnu0vottralqomb05cndf.apps.googleusercontent.com',
  },
  microsoft: {
    clientId: '40b041a7-1128-4829-b0c2-abf5c058b569',
    tenantId: '7b8d1e4e-b3a3-4df5-a12c-056ac9cdcbf4',
    redirectUrl: 'https://flex.dmsone.hu/',
    openidConfiguration: 'https://login.microsoftonline.com/7b8d1e4e-b3a3-4df5-a12c-056ac9cdcbf4/v2.0/.well-known/openid-configuration',
  },
}

export default config;
