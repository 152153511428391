import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatBadge} from "@angular/material/badge";
import {MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCardTitleGroup
} from "@angular/material/card";
import {
  MatExpansionPanel,
  MatExpansionPanelActionRow, MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf, NgOptimizedImage, NgSwitchCase} from "@angular/common";
import {SharedModule} from "../../../modules/shared/shared.module";
import {DmsNewsItem} from "../../../model/dmsNewsItem";
import {DmsPossibleWfTaskResultsSchema} from "../../../model/dmsPossibleWfTaskResultsSchema";
import {IncomingDataAttachments} from "../../../shared/interfaces/shared/IncomingDataAttachments";
import {SafeHtml} from "@angular/platform-browser";
import {DmsMetadataSchema} from "../../../model/dmsMetadataSchema";
import {DmsMfTorzsItemsByLeiro} from "../../../model/dmsMfTorzsItemsByLeiro";
import {DmsWfTaskResultItem} from "../../../model/dmsWfTaskResultItem";
import {DialogForwardBoxComponent} from "../dialog-forward-box/dialog-forward-box.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {DmsWfTasksService} from "../../../services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../services/api/dms-tasks.service";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectSendContentById} from "../../../store/selectors/form.selectors";
import {selectTaskSendContentById} from "../../../store/selectors/formtask.selectors";
import {FormWorkflowState} from "../../../store/states/formWorkflowState";
import {FormTaskState} from "../../../store/states/formTaskState";
import {setSendContent} from "../../../store/actions/form.actions";
import {setTaskSendContent} from "../../../store/actions/formtask.actions";
import {DialogAttachmentBoxComponent} from "../dialog-attachment-box/dialog-attachment-box.component";
import {DmsNewsCommentItem} from "../../../model/dmsNewsCommentItem";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {DmsFavoriteService} from "../../../services/api/dms-favorite.service";

@Component({
  selector: 'app-feed-card-new',
  standalone: true,
  imports: [
    FormsModule,
    MatBadge,
    MatButton,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCardTitleGroup,
    MatExpansionPanel,
    MatExpansionPanelActionRow,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatFormField,
    MatHint,
    MatIcon,
    MatInput,
    MatLabel,
    MatMiniFabButton,
    MatOption,
    MatProgressBar,
    MatSelect,
    NgForOf,
    NgIf,
    NgSwitchCase,
    SharedModule,
    NgOptimizedImage,
    MatExpansionPanelDescription,
    CdkTextareaAutosize,
    MatIconButton
  ],
  templateUrl: './feed-card-new.component.html',
  styleUrl: './feed-card-new.component.scss'
})
export class FeedCardNewComponent implements OnInit, OnDestroy{

  @ViewChild('input') inputElement!: ElementRef<HTMLElement>;
  @ViewChild('expansionPanel') expansionPanel!: MatExpansionPanel;

  @Input() type!: DmsNewsItem.TypeEnum | undefined;
  @Input() status!: string | undefined;
  @Input() subject!: string | undefined;
  @Input() taskStart!: string | undefined;
  @Input() taskDeadline!: string | undefined;
  @Input() taskName!: string | undefined;
  @Input() possibleResults!: DmsPossibleWfTaskResultsSchema | undefined;
  @Input() id!: number;
  @Input() referenceNumber!: string;
  @Input() attachments!: IncomingDataAttachments[] | undefined;
  @Input() comments!: DmsNewsCommentItem[] | undefined;
  @Input() wfDescription!: SafeHtml;
  @Input() taskDescription!: SafeHtml;
  @Input() metaItem!: Array<DmsMetadataSchema> | undefined;
  @Input() mfTorzsOptionList!: DmsMfTorzsItemsByLeiro[];
  @Input() result!: string;
  @Input() isFavorite!: boolean;
  @Input() icon!: string;

  isStar: boolean = false;

  selectedResult!: DmsWfTaskResultItem;

  sendTaskResult!: string;

  sendComment: string =  '';

  previewMetaItems: Array<DmsMetadataSchema> = [];

  tempMetadata: Array<DmsMetadataSchema> = [];

  deadlineCounter = 'Nincs határidő';

  isSmallScreen: boolean = false;
  isMediumScreen: boolean =false;

  headerHeight = '100px';

  private sendContentSubscription: Subscription | undefined;
  private sendTaskContentSubscription: Subscription | undefined;
  sendContents: { comment: string; metadata: Array<DmsMetadataSchema> } = {comment: '', metadata: []};
  sendTaskContents: { comment: string } = {comment: ''};
  hasPreSaveData: boolean = true;

  possibleTaskResults: DmsWfTaskResultItem[] = [
    { id: '1_elfogadva', displayName: 'Elfogadva' },
    { id: '1_lezarva', displayName: 'Lezárva' },
    { id: '1_elutasitva', displayName: 'Elutasítva' },
  ];

  constructor(
    public dialog: MatDialog,
    public workflowItemService: DmsWfTasksService,
    public taskItemService: DmsTasksService,
    private dmsFavoriteService: DmsFavoriteService,
    private storeWorkflow: Store<{ form: FormWorkflowState }>,
    private storeTask: Store<{ formTask: FormTaskState }>,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    public router: Router
    ) {
  }

  ngOnInit() {
    this.deadlineCounter = this.getTimeLeft(this.taskDeadline);

    this.breakpointObserver.observe([
      "(max-width: 768px)",
      "(min-width: 769px) and (max-width: 1023px)"
    ]).subscribe((result: BreakpointState) => {
      if(result.breakpoints["(max-width: 768px)"]) {
        this.isSmallScreen = true;
        this.isMediumScreen = false;
        this.headerHeight = 'auto';
        this.cdr.detectChanges();
      } else if (result.breakpoints["(min-width: 769px) and (max-width: 1023px)"]) {
        this.isSmallScreen = false;
        this.isMediumScreen = true;
        this.headerHeight = '200px';
        this.cdr.detectChanges();
      } else {
        this.isSmallScreen = false;
        this.isMediumScreen = false;
        this.headerHeight = '100px';
        this.cdr.detectChanges();
      }
    })

    if(this.metaItem){
      this.metaItem.forEach(item =>{
        if(item.is_preview){
          this.previewMetaItems.push(item);
        }
      })
    }
    if(this.id && this.type === 'WfTask') {

      if(this.possibleResults){
        this.possibleResults.forEach( result => {
          if(result.isSelected){
            this.selectedResult = result;
          }
        })
      }

      if(this.isMandatoryComment()){
        this.triggerCommentValidator();
      }

      this.sendContentSubscription = this.storeWorkflow.pipe(
        select(selectSendContentById(this.id))
      ).subscribe((filteredContent) => {
        if (filteredContent) {
          this.sendContents = structuredClone(filteredContent as {
            comment: string;
            metadata: Array<DmsMetadataSchema>
          });

          this.sendComment = this.sendContents.comment;
          this.metaItem = this.sendContents.metadata;

          this.hasPreSaveData = true;
        }
      });
    } else if(this.id && this.type === 'Task') {
      this.sendTaskContentSubscription = this.storeTask.pipe(
        select(selectTaskSendContentById(this.id))
      ).subscribe((filteredContent) => {
        if (filteredContent) {
          this.sendTaskContents = structuredClone(filteredContent as {
            comment: string;
          });

          this.sendComment = this.sendTaskContents.comment;

          this.hasPreSaveData = true;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.sendContentSubscription) {
      this.sendContentSubscription.unsubscribe();
    }
    if(this.sendTaskContentSubscription) {
      this.sendTaskContentSubscription.unsubscribe();
    }
    if (this.id&&this.metaItem&&this.type === 'WfTask') {
      this.storeWorkflow.dispatch(setSendContent({
        id: this.id,
        sendContent: {
          comment: this.sendComment,
          metadata: this.metaItem
        }
      }));
    } else if (this.id&&this.type === 'Task') {
      this.storeTask.dispatch(setTaskSendContent({
        id: this.id,
        sendTaskContent: {
          comment: this.sendComment,
        }
      }));
    }
  }

  completeTask(){
    if (this.selectedResult && this.selectedResult.id.length>0) {
      this.sendTaskResult = this.selectedResult.id;
      this.sendCompleteRequest();
    } else {
      alert('Eredmény kitöltése kötelező!');
    }
  }

  private sendCompleteRequest(){
    if(this.type=='WfTask') {
      this.sendWorkflowRequest();
    } else if(this.type=='Task') {
      this.sendTaskRequest();
    } else alert('Hiba a feladat típusában!');
  }

  private sendTaskRequest(){
    if(this.sendTaskResult==='1_elfogadva'){
      this.taskItemService.createDmsTaskAccept$(Number(this.id), this.sendComment).subscribe( response => {
        this.reloadPage();
      })
    } else if(this.sendTaskResult==='1_lezarva'){
      this.taskItemService.createDmsTaskComplete$(Number(this.id), this.sendComment).subscribe( response => {
        this.reloadPage();
      })
    } else if(this.sendTaskResult==='1_elutasitva'){
      this.taskItemService.createDmsTaskDeny$(Number(this.id), this.sendComment).subscribe( response => {
        this.reloadPage();
      })
    } else alert('Hiba a kiválasztott eredménnyel!');
  }

  private sendWorkflowRequest(){
    if(this.metaItem && this.checkMandatoryDataFill() && this.checkMandatoryCommentFill()) {
      this.tempMetadata = this.metaItem;
      let send_metadata: { code: string, value: string | number | null }[];
      send_metadata = this.tempMetadata.map(item =>
        ({
          code: item.code,
          value: item.type === 'Check' ? (item.value ? '1' : '0') : item.value
        })
      );
      send_metadata.forEach(item => {
        if (item.value == '') item.value = null;
      })
      const sendContent = {
        comment: this.sendComment,
        wfTaskResult: this.sendTaskResult,
        metadata: send_metadata.reduce<{ [key: string]: any }>(
          (acc, item) => {
            acc[item.code] = item.value;
            return acc;
          }, {})
      };
      const sendContentJson = JSON.stringify(sendContent, null, 2);
      this.workflowItemService.createDmsWfTaskComplete$(Number(this.id), sendContentJson).subscribe(response => {
        this.reloadPage();
      });
    } else alert('Kérjük töltse ki a kötelező mezőket küldés előtt!');
  }

  countAttachments(){
    if(this.attachments&&this.attachments.length>0){
      return this.attachments.length
    } else return 0;
  }

  countMessages(){
    if(this.comments){
      return this.comments.length;
    } else return 0;
  }

  routeToInfo(){
    if(this.type === "WfTask"){
      return '/workflow/'+this.id;
    } else return '/task/'+this.id;
  }

  onForwardTask() {
    this.dialog.open(DialogForwardBoxComponent, {
      data: {
        type: this.type,
        id: this.id,
        name: this.type === "WfTask" ? this.taskName : this.subject,
        subject: this.subject,
      },
      autoFocus: false
    })
  }

  callAttachments(){
    this.dialog.open(DialogAttachmentBoxComponent,  {
      autoFocus: false,
      data: this.attachments
    });
  }

  isResult(){
    return this.selectedResult && this.selectedResult.id.length > 0;
  }

  private reloadPage(){
    this.router.navigate(['/pageloading']);
  }

  private checkMandatoryDataFill(): boolean {
    if (this.metaItem) {
      return !this.metaItem.some(item =>
        item.visibility === 'MT_K' && (item.value === null || item.value === undefined || item.value === ''));
    }
    return true;
  }

  private checkMandatoryCommentFill(): boolean {
    if (this.isMandatoryComment()) {
      return this.sendComment.length > 0;
    }
    return true;
  }

  possibleTaskResultsSorting() {
    if (this.type === 'WfTask') {
      return this.possibleResults;
    } else if (this.type === 'Task') {
      if (this.status && this.status === '1_elfogadva') {
        return this.possibleTaskResults.slice(1);
      } else return this.possibleTaskResults;
    } else return [];
  }

  starClicked(){
    let type: string;
    if(this.type === 'Task'){
      type = 'task';
    } else type = 'wfTask';
    this.dmsFavoriteService.createDmsFavorite$(type, this.id.toString(), !this.isFavorite)
      .subscribe( response => {
      this.reloadPage();
    })
  }

  getTimeLeft(deadline: string | undefined): string {
    if(deadline) {
      const currentTime = new Date();
      const deadlineTime = new Date(deadline);
      const timeDifference = deadlineTime.getTime() - currentTime.getTime();

      if (timeDifference <= 0) {
        return 'Lejárt határidő!';
      }

      const minutes = Math.floor(timeDifference / (1000 * 60));
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

      if (years >= 1) {
        return `${years} év`;
      } else if (days >= 1) {
        return `${days} nap`;
      } else if (hours >= 1) {
        return `${hours} óra`;
      } else {
        return `${minutes} perc`;
      }
    } else return 'Nincs határidő'
  }

  getLastComment(){
    if(this.comments && this.comments[0] && this.comments[0].comment){
      return this.comments[0].comment;
    } else {
      return '-';
    }
  }

  getLastCommentData(){
    if(this.comments && this.comments[0] && this.comments[0].userName && this.comments[0].date) {
      return ' (' + this.comments[0].userName + ', ' + this.dateFormatting(this.comments[0].date, false) + ')';
    } else {
      return '';
    }
  }

  getHeaderActionLayout(){
    if(this.isSmallScreen){
      return 'small-screen-header-action-div add-row-gap-12';
    } else if(this.isMediumScreen){
      return 'small-screen-header-action-div';
    } else {
      return 'big-screen-header-action-div';
    }
  }

  getHeaderResultLayoutNonActive(){
    if(this.isSmallScreen){
      return 'small-screen-non-active-result-div';
    } else if(this.isMediumScreen){
      return 'medium-screen-non-active-result-div';
    } else {
      return 'big-screen-non-active-result-div';
    }
  }

  getHeaderResultLayout() {
    if(this.isMediumScreen){
      return 'header-result-layout-medium';
    } else {
      return 'flex-row-centered';
    }
  }

  isActiveStatus(){
    return this.status === '1_elfogadva' || this.status === '1_letrehozva' || this.status === 'FA_U';
  }

  isMandatoryComment(){
    return !!(this.selectedResult && this.selectedResult.isCommentRequired);
  }

  triggerCommentValidator() {
    let commentElement: HTMLElement = this.inputElement.nativeElement;
    commentElement.click();
  }

  setCommentState(){
    if(this.isMandatoryComment()) {
      this.expansionPanel.open();
      setTimeout(() => {
        if (this.inputElement && this.inputElement.nativeElement) {
          this.inputElement.nativeElement.focus();
          this.inputElement.nativeElement.blur();
          this.inputElement.nativeElement.click();
        }
      }, 300);
    }
    this.triggerCommentValidator();
  }

  dateFormatting(timestamp: string | null, date?: boolean){
    if (timestamp && timestamp.length>1) {
      let dateArray = timestamp.split('T');
      dateArray[1] = dateArray[1].substring(0, 5);
      if (date) {
        return dateArray[0];
      } else {
        return dateArray.join(' ');
      }
    }
    else return '';
  }

}
