import { NgModule } from '@angular/core';
import {DashboardComponent} from "../../components/mvp/dashboard/dashboard.component";
import {AdminDashboardComponent} from "../../components/admin/admin-dashboard/admin-dashboard.component";
import {SharedModule} from "../shared/shared.module";
import {SidenavComponent} from "../../components/container/sidenav/sidenav.component";
import {BodyComponent} from "../../components/container/body/body.component";
import {LoginComponent} from "../../components/login/login.component";
import {HeaderActionsComponent} from "../../components/container/toolbar/header-actions/header-actions.component";
import {ContainerComponent} from "../../components/container/container.component";
import {ToolbarComponent} from "../../components/container/toolbar/toolbar.component";
import {SpinnerComponent} from "../../components/container/spinner/spinner.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MvpAuthModule} from "../mvp-auth.module";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {CustomHttpInterceptor} from "../../services/shared/http-interceptor";
import {MatExpansionModule} from "@angular/material/expansion";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FeedCardNewComponent} from "../../components/shared/feed-card-new/feed-card-new.component";
import {MatTab, MatTabGroup} from "@angular/material/tabs";

@NgModule({
  declarations: [
    DashboardComponent,
    AdminDashboardComponent,
    SidenavComponent,
    BodyComponent,
    LoginComponent,
    HeaderActionsComponent,
    ContainerComponent,
    ToolbarComponent,
    SpinnerComponent,
  ],
  imports: [
    SharedModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatButtonModule,
    MatInputModule,
    MvpAuthModule,
    MatExpansionModule,
    FlexLayoutModule,
    FeedCardNewComponent,
    MatTabGroup,
    MatTab
  ],
  exports: [
    DashboardComponent,
    AdminDashboardComponent,
    SidenavComponent,
    BodyComponent,
    LoginComponent,
    HeaderActionsComponent,
    ContainerComponent,
    ToolbarComponent,
    SpinnerComponent,
  ],
  providers: [
    // other providers
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
  ]
})
export class ContainerModule { }
